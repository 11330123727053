.video-container {
  position: relative;
  width: 100%;
  height: 85vh;
  border-radius: 0 0 40px 40px;
  overflow: hidden;
  background: black;

  background-color: black;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'brightness\'><feColorMatrix type=\'matrix\' values=\'1.2 0 0 0 0 0 1.2 0 0 0 0 0 1.2 0 0 0 0 0 1.2 0\'/></filter></svg>#brightness");
  /* Firefox 3.5+ */
  -webkit-filter: brightness(108.5%);
  /* Chrome 19+ & Safari 6+ */
}

.adcon {
  position: absolute;
  top: 0;
  background: #3f3f3f;
  width: 0px;
  z-index: 120000;
  left: 0;
  height: 0px;

  background: url('../../assets/ad_error_preview.png') no-repeat center center;
  background-size: cover; /* Ensures the background covers the element */

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

}

.error-con
{
  display: flex;
  text-align: left;
  flex-direction: row;
  justify-content: center;
  align-items: top;

  h2 {
    margin-top: 30px;
  }
}


.noadcon {
  position: absolute;
  top: 0;
  background: #3f3f3f;
  width: 100vw;
  z-index: 120000;
  left: 0;
  height: 100%;

 
  background-size: cover; /* Ensures the background covers the element */

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

}

.skip-button {
  position: absolute;
  height: 25px;
  width: auto;
  text-align: center;
  align-items: center;
  cursor: pointer;
  justify-content: center;

  right: 70px;
  bottom: 70px;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px 20px;
  border-radius: 20px;
  transform: translateX(-0%);
  font-weight: 600;
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.skip-button:hover {
  opacity: 0.9;
  scale: 0.95;
  transition: all 200ms ease-in-out;
}

.fill-animation {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  background-color: rgba(255, 255, 255, 0);

  transform: scaleX(0);
  transform-origin: left;
  transition: transform 3s linear;
}

.fill-animation.fill {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);

  transition: transform 3s linear;

}

.skip-button.filling {
  opacity: 1;

  transition: all 5000ms ease-in-out;
}


.tooltip-video {
  position: absolute;
  height: 25px;
  width: 150px;
  text-align: center;
  align-items: center;
  justify-content: center;

  bottom: 40px;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;

  padding: 5px 20px;
  border-radius: 20px;
  transform: translateX(-0%);
  font-weight: 600;

  transition: all 200ms ease-in-out;
}

.tooltip-video.hide {

  opacity: 0;

  transition: all 0ms ease-in-out;

}




@media (max-width: 900px) {
  .video-container {
    width: 100%;
    margin-top: 80px;
    height: 35vh;
    border-radius: 0 0 20px 20px;
  }

  .countdown-overlay {
    position: absolute;
    bottom: 10px;
    left: 50%;
    visibility: visible;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1;
    color: white;
    padding: 5px 10px;
    gap: 10px;
    z-index: 10;
    border-radius: 20px;
    text-align: center;
    transition: all 0.2s ease-in-out;
  }

  .countdown-overlay.hidden {
    visibility: hidden;
    opacity: 0;
  }



  .time-text {
    display: none;
  }

  .controls-button {
    width: 20px;
    height: 20px;
    margin: 0px 5px;


  }

  .controls-input {
    width: 60%;
    margin: 0 5px;
  }

  .controls-input.vertical {
    width: 40px;
    height: 10px;
    transform: translateX(-40%) translateY(-1200%) rotateZ(-90deg);
  }
}

.countdown-overlay.hidden {

  visibility: hidden;
  position: absolute;
  bottom: 30px;
  left: 50%;

  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  color: white;
  padding: 10px 20px;
  z-index: 10px;
  border-radius: 40px;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

.countdown-overlay {
  position: absolute;
  bottom: 30px;
  left: 50%;
  visibility: visible;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
  color: white;
  padding: 10px 20px;
  z-index: 10px;
  border-radius: 40px;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

.countdown-overlay a {
  margin-bottom: 20px;
  font-weight: 700;
  z-index: 10;
}

.countdown-overlay button {
  padding: 10px 20px;
  margin-right: 0px;
  background-color: rgba(134, 134, 134, 0.205);
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.countdown-overlay button:hover {
  background-color: rgba(20, 20, 20, 0.842);
}


.video-container.fullscreen {
  border-radius: 0;
  /* Remove border-radius when in fullscreen */
}

.video-container.loading .video-player {
  background-color: black;
}

.video-player {
  width: 100%;
  height: 100%;
  background-color: black;
  background: black;

}

.video-player-pre {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: black;
  background: black;
  z-index: 20;

}

.video-player-theatre {
  width: 100%;
  height: 100%;
  background-color: black;
  background: black;
  cursor: none
}

.controls {
  position: absolute;
  bottom: 0px;
  z-index: 4;
  left: 0;
  right: 0;
  padding: 0 10px;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
}

.controls-hide {
  opacity: 0;
  transition: all 0.5s;
}

.img-button {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  fill: white;
  color: white;


}



.time-text {

  margin: 0px 10px;
  text-indent: -8px;
}

.controls-button {
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0px;
  margin: 0px 10px;

}

.controls-button:hover {
  background: transparent;
  opacity: 0.8;
  scale: 0.9;

}


.controls-input {
  width: 80%;
  margin: 0 10px;
  border: none;

  background-color: white;
  -webkit-appearance: none;
  appearance: none;

  cursor: pointer;
  outline: none;
  border-radius: 15px;
  /*  overflow: hidden;  remove this line*/

  /* New additions */
  height: 8px;
  padding: 0px;
  background: white;
  border: none;

}

.controls-input.fullscreen {
  width: 100%;
}


.controls-input:focus {

  outline-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
}

/* Thumb: webkit */
.controls-input::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  height: 18px;
  width: 18px;
  background-color: white;
  ;
  border-radius: 100%;
  border: none;
  margin: 0px;
  padding: 0px;

  /* box-shadow: -407px 0 0 400px #f50; emove this line */
  transition: .2s ease-in-out;
}

.controls-input::-webkit-slider-thumb:hover {
  /* removing default appearance */
  scale: 1.3;

  /* box-shadow: -407px 0 0 400px #f50; emove this line */
  transition: .2s ease-in-out;
}


/* Thumb: Firefox */
.controls-input::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: white;
  ;
  border-radius: 50%;
  border: none;

  /* box-shadow: -407px 0 0 400px #f50; emove this line */
  transition: .2s ease-in-out;
}



.volume-controls {
  position: absolute;



  opacity: 0;
  transition: opacity 0.3s;
}

.fullscreeen-centered {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  z-index: 2;
}

.fullscreeen-centered-bpb {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;


  z-index: 200;
}

.big-play-button {
  width: 80px;
  height: 80px;
  transition: .2s ease-in-out;
  z-index: 20;
}


.big-play-button:hover {
  width: 80px;
  height: 80px;
  scale: 0.8;
  opacity: 0.8;
  cursor: pointer;
  transition: .2s ease-in-out;
}


.controls-input.vertical {


  width: 80px;
  height: 10px;
  transform: translateX(-45%) translateY(-1000%) rotateZ(-90deg);


  display: inline-block;
}

.controls button:hover .volume-controls {
  opacity: 1;
}

@media (max-width: 1800px) {
  .controls {
    padding: 0 20px;
    padding-bottom: 8px;
  }

  .video-container {
    border-radius: 0 0 30px 30px;
  }
}