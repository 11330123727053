
.afk-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top of other elements */
}

.afk-popup-content {
    background-color: rgb(39, 39, 39);
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.video-container2 {
    position: relative;
    width: 100%;
    height: 100%;
}

.VideoPlayer {
    position: relative;
    z-index: 1; /* Lower z-index than the ad */
}

.ad {
    position: absolute;
  
    display: flex;
    justify-content: center;  /* Horizontally center */
    align-items: center;      /* Vertically center */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;  /* Ensure it's on top */
    background-color: #232322; /* Optional: semi-transparent background */
}

#ety-cloud-player {
    position: absolute;
 
    background-color: white; /* Example background for the ad content */
}

.afk-popup-content h2 {
    margin: 0 0 10px;
    font-size: 24px;
}

.afk-popup-content p {
    margin: 0 0 20px;
    font-size: 16px;
}

.afk-popup-content button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;


    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}




.small-text
{
    margin: 0px;
    margin-bottom: 8px;
    margin-top: 8px;
    text-decoration: none;
}

.series-title
{
    margin: 0px;
    margin-bottom: 20px;
}
.solid-back
{
    background-color: #111111;
}



.anime-top-con {

    display: flex;
    justify-content: space-between; /* This will push the button to the right */
    align-items: center; /* Align items vertically center */
}

.anime-next-series-button {
    border: none;
    border-radius: 40px;
    height: 50px;
    padding: 0 20px;
    background-color: #ffffff36;
    text-align: center;
    transition: all 0.3s ease-out;
}

.anime-next-series-button:hover {
    background-color: rgba(255, 255, 255, 0.075);
    transform: scale(0.95); /* Corrected from 'scale' to 'transform' */
}

.anime-snippet {
    display: flex;
 
    gap: 15px;
    background: #d8d8d823;
    padding: 6px 0;
    padding-right: 22px;
    padding-left: 8px;
    margin: 0px;
    border-radius: 30px;
    margin-bottom: 12px;
    max-width: fit-content;
    align-items: center;
    cursor: pointer;
    scale: 1;
 
    transition: all 0.2s ease-out;
}

.anime-snippet:hover {
    scale: 0.95;
    background: rgba(255, 255, 255, 0.068);
    transition: all 0.2s ease-out;
}

.anime-snippet-text
{
    gap: 20px;
}

.anime-snippet-img
{
    width: 35px;
    height: 35px;
    border-radius: 30px;
    margin: 0px;
    padding: 0px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
}

.ad-banner
{
    text-align: left;
    background: linear-gradient(to left, #482a6185, #40074685);
    padding: 30px;
    border-radius: 20px;
}

.watch-half-con {
    background: #111111;
    padding: 20px 40px;
    margin: 0px;
    display: flex;
}

.watch-half {
    flex: 3;
}


.watch-half-comments {
    flex: 1;
    padding: 0 20px;
    display: flex;
    flex-direction: column;

    h2 {
        margin: 10px 0;
        
    }


}

.watch-comments-title
{
    display: flex;
    flex-direction: row;
    gap: 10px;

    img {
        width: 35px;
    }
}