.upload-content {
    text-align: center;
    margin: 100px auto;
  
  }

  .image-tasty
  {
    height: 50px;
    width: auto;
    opacity: 0.2;
    border-radius: 200px;
    transition: all 10s ease-in-out;
  }

  .image-tasty:hover
  {
    height: 80vh;
    opacity: 1;
    border-radius: 10px;
    transition: all 2s ease-in-out;
  }
  
  .upload-form {
    display: grid;
   text-align: center;
    margin: 0 10%;

    place-items: center; 
    grid-template-columns: repeat(auto-fit,  minmax(350px, 1fr));
  
  }
  
  .input-group {
    margin-bottom: 15px; /* Adjust spacing between input groups */
    width: 300px;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #7544B2; /* Change color as needed */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite; /* Apply the animation */
    margin: 20px auto;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  