.watch-room {
    z-index: 100;
    width: 300px;
    align-items: center;
    position: fixed;
    background: #242424c4;
    border-radius: 30px;
    left: 2%;
    top: 12%;
    backdrop-filter: blur(10px);

    font-family: Arial, sans-serif;
    padding: 0px 20px;
    padding-top: 20px;
    transition: all 0.2s ease-in-out;

    input {
        width: 250px;
        background: #24242460;
        margin: 0px;
        margin-bottom: 18px;
        margin-top: 5px;
    }

    h2 {
        margin-top: 0;
        margin-bottom: 10px;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 10px;
    }
}

.room-settings.hidden
{
    opacity: 0;
}

.watch-room.fullscreen {
    left: 10px;
    top: 1%;


}

.watch-room.hidden {
    background: #ffffff00;
    backdrop-filter: none;
    cursor: none;
    left: 0%;
    top: 0%;

    .chat-window ul {
     
        height: 100px;
    }

    h3 {
        margin-top: 0;
        visibility: hidden;
        height: 0px;
        cursor: none;
    }

    input {
        cursor: none;

        opacity: 0;
        cursor: none;
        visibility: hidden;
        height: 0px;
    }

    .hint {
        cursor: none;
        visibility: hidden;
        height: 0px;
        opacity: 0;
    }

    .room-key {
        cursor: none;
        visibility: hidden;
        height: 0px;
    }
}

.room-settings {
    margin-bottom: 20px;
}

.room-settings label {
    display: block;
    margin-bottom: 10px;
}

.room-settings input {
    margin-left: 10px;
}

.img-button-watchroom {
    position: absolute;
    top: 13%;
    left: 4%;
    width: 20px;
    height: 20px;
    margin: 0px;
    padding: 0px;
    transition: all 0.2s ease-in-out;

}

.img-button-watchroom.hidden {
    opacity: 0;
}

.img-button-watchroom.fullscreen {
    top: 2%;
    left: 2%;
}

.img-button-watchroom:hover {
    opacity: 0.6;
    cursor: pointer;
}


.room-key {
    color: #bd42f7;
    margin: 0px;
    width: 100%;
    font-weight: 900;
    font-size: 16px;
    transition: all 0.1s ease-in-out;
}

.room-key:hover {
    color: #bd42f7;
    margin: 0px;
    width: 100%;
    font-weight: 900;
    cursor: pointer;
    font-size: 16px;
    opacity: 0.6;
}

.hint {
    color: #ffffff8a;
    margin: 0px;
    width: 100%;
    text-align: center;
    font-size: 14px;
}

.watch-room-sender {
    color: #980ada;
    font-weight: 900;
}

.chat-window {


    max-width: 600px;
    margin: 0 auto;
}


@media (max-width: 720px) {
    .img-button-watchroom{
        visibility: hidden;
        height: 0px;
    }


}

.chat-window ul {
    list-style-type: none;
    height: 100px;

    margin: 0;
    overflow-y: scroll;

    padding: 0;
    transition: all 0.2s ease-in-out;
}

.chat-window li {
    margin: 5px 0;


}

.chat-message-text {

    word-wrap: break-word;
    word-break: normal;
    white-space: pre-wrap;
}

.chat-window input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

button .watch-room-icon {
    margin-right: 8px; /* Space between icon and text, adjust as needed */
}

button.watch-room-button {
    width: 100%;
    margin: 0px;
    text-align: left;
    padding: 14px 20px;

    span {
        margin: 0px;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    p {
        margin: 0px;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 500;
    }

    input[type="text"] {
        width: 100%;
        padding: 10px;
        margin: 10px 0px;
        margin: 0px;
      
       
        border-radius: 24px;
    }

    .check-icon
    {
        background: #FFF;
      
        margin: 0px;
        margin-left: 10px;
        padding:  10px;
        height: 15px;
        border-radius: 20px;
        margin-bottom: 0px;
        transition: all 0.2s ease-in-out;
      
     
    }

    
    .check-icon:hover
    {
        opacity: 0.7;
        scale: 0.9;
      
     
    }
}
.watch-code-input-con
{
    display: flex;
    margin-top: 10px;
    align-items: center;
}
.button-sec.watch-room-button {
    width: 100%;
    margin: 0px;
    text-align: left;
    padding: 14px 20px;

    p {
        margin: 0px;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 500;
    }
}

.cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}


.card {
    background-color: #94949428;

    border-radius: 18px;
    padding: 20px;
    margin: 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex: 1;
}

.create-join-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logout-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 10px;
    transition: background 0.2s ease-in-out;
    position: absolute;
    right: 10px;
    top: 0px;
}

.logout-button:hover {
    background: #2e2e2e;
}
.logout-button.hidden {
    height: 0px;
}