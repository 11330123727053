.anime-card {
    position: relative;
    width: 200px;
    height: 300px;
    border-radius: 20px;
    margin: 0 10px;
    overflow: hidden;
    margin-bottom: 20px;
    transition: all 0.3s ease;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.5);
    flex: 0 0 auto;
    /* Fix the size of the elements */
    background: rgb(41, 41, 41);
}

.anime-card:hover {
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.3);
    background: black;
    transform: translateY(5px)
}


.anime-thumbnail {
    opacity: 0;

    width: 100%;
    height: 100%;
    background: #111111;
    transition: all 0.3s ease;
    scale: 1;
}

.anime-thumbnail.loaded {
    opacity: 1;
    /* Display image after animation */
}

.anime-description {
    width: 170px;


}

.anime-thumbnail.hovered {
    opacity: 0.2;
    background: black;
    filter: blur(3px);
    scale: 1.2;

    transition: all 0.3s ease;
    /* Add transition property */
}

.anime-text-container {
    position: absolute;
    color: white;
    overflow: hidden;
    height: 190px;
 


    top: 0;
    margin: 20px 12px;
    padding: 10px 0px;
}

.anime-info {
    position: absolute;
    top: 0;
    left: 0;


    width: 100%;
    height: 100%;

    cursor: pointer;
    



    opacity: 0;

}


@media (max-width: 720px) {
    .button-sec.watch-button {
        position: absolute;
        bottom: 4px;
        right: 0;
        left: 0;
        background: #ffffff2f;
    }

    .anime-title-card {
        font-size: 14px;
        word-wrap: break-word;
        overflow: hidden;

        width: 110px;

    }


    .anime-description {
        height: 0;
        opacity: 0;

    }

    .anime-card {
        position: relative;
        width: 140px;
        height: 200px;
        border-radius: 20px;
        margin: 0 5px;

        overflow: hidden;

        transition: all 0.3s ease;
        box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.5);
        flex: 0 0 auto;
        /* Fix the size of the elements */
        background: rgb(41, 41, 41);

    }

    .anime-thumbnail {
        background: black;
       
        scale: 1.2;

        transition: all 0.3s ease;
        /* Add transition property */
    }


    .anime-info {
        position: absolute;
        top: 0;
        left: 0;
        overflow: auto;


        width: 100%;
        height: 100%;


        z-index: 3;



        opacity: 1;

    }

    .anime-info {
        overflow: hidden;
        background: #00000086;
        
        transition: all 0.3s ease;

    }

}


.anime-info:hover {
    opacity: 0.8;

    transition: all 0.3s ease;
    z-index: 10;

}

.rating {
 
    background: #38383879;
    margin: 0;
    backdrop-filter: blur(8px);
    color: white;
    padding: 5px 10px;
    border-radius: 100px;




}

.badges
{
    position: absolute;
    top: 10px;

    right: 10px;
    display: flex;
    gap: 10px;
}



.viral {
 
    background: #ff1e0079;
    margin: 0;
    backdrop-filter: blur(8px);
 
    
    padding: 4px 10px;

    border-radius: 100px;
    fill: white;

 
}

.hq {
 
    background: #7700ff79;
    margin: 0;
    backdrop-filter: blur(8px);
 
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 4px 10px;
    z-index: 1;

    border-radius: 100px;
    fill: white;
    transition: all 0.2s ease-in-out;

 
}

.hq.hovered
{
    scale: 0.8;
    opacity: 0;
}

.rating.premium {

    background: #ffd900c2;
    color: black;

}

.rating.good {

    background: #3ba100c2;


}


.button-sec.watch-button {
    position: absolute;
    bottom: 4px;
    margin: 5px 5px;
    right: 0;
    left: 0;
}