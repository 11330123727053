.not-popup.gone {
    opacity: 0;
    visibility: hidden;
}

.not-popup {
    display: block;
    position: absolute;
    max-height: 500px;
    overflow: scroll;
    top: 100%;
    right: 2%;
    background-color: #333;
    color: white;
    padding: 0px 10px;
    opacity: 1;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: all 0.2s ease-in-out;
}

.not-popup ul {
    list-style: none;
    margin: 0;
    padding: 10px 0;
}

.not-popup li:hover {
    background: #ffffff11;
}

.not-popup li {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    margin: 5px 0px;

    border-radius: 15px;
    transition: all 0.3s ease;

}

.not-popup p {
    width: 100%;
    display: inline-flex;
    /* Inline-flex to keep it inline with other elements */
    align-items: center;
    /* Center vertically */

    padding: 0;
    /* Reset padding */
    margin: 0px;
}

.not-popup li .icon {
    width: 20px;
    /* Adjust the size as necessary */
    height: 20px;
    /* Adjust the size as necessary */
    margin-right: 10px;
}

.not-popup li a:hover {
    text-decoration: none;
}

.not-popup li a {
    color: white;
    align-items: left;
    /* Center vertically */
    justify-content: center;
    /* Center horizontally */
    display: flex;
    /* Flex container for centering */

    /* Center vertically */
    justify-content: left;

    padding: 0px;
    text-decoration: none;
}

.notification {
    display: flex;

    flex-direction: column;
    cursor: pointer;
    align-items: flex-start;
    text-align: left;
}

.notification-date {
    font-size: 14px;
    color: rgb(194, 194, 194);
    margin-top: 8px;
    text-align: left;
}

.notification-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    border: 2px solid white; /* Optional: Add a white border */
  }

.notification-message {
    font-size: 16px;
    width: 300px;
    font-weight: 500;
    text-overflow: ellipsis;
    word-wrap: break-word;
}