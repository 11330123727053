.user-leaderboard-con
{
    position: relative;
    background: #ffffff15;
    padding: 20px 30px;
   
    border-radius: 20px;
    transition: all 100ms ease-in-out;
    h4
    {
        margin: 0px;
        margin-top: 15px;
        padding: 0px;
       
    }

    h1 {
        margin: 0px;
        margin-bottom: 25px;
    }
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
    width: 100%;
    height: 100%;
 
  
    background-position: center;
    object-fit: cover; /* Ensure the image covers the entire area */
    z-index: 0; /* Ensure it is behind other content */
    opacity: 0.4; /* Set opacity for the background image */
   
}

.user-leaderboard-con div {
    position: relative;
    z-index: 1; /* Ensure it is above the background image */
}

.user-leaderboard-con.sub
{
    background: #ffffff00;
    transition: all 200ms ease-in-out;

}

.user-leaderboard-con.sub:hover
{
    background: linear-gradient(150deg, #4b00e063, #8d2de265);
    transition: all 200ms ease-in-out;
}


.user-leaderboard-con.top
{
    background: #fff;
    padding: 20px 30px;
    border-radius: 20px;
    transition: all 500ms ease-in-out;
    h4
    {
        color: #7544B2;
        margin: 0px;
        margin-top: 15px;
        padding: 0px;
        transition: all 500ms ease-in-out;
    }

    h1 {
        color: #7544B2;
        margin: 0px;
        margin-bottom: 25px;
        transition: all 500ms ease-in-out;
    }
}
.user-leaderboard-con.top:hover
{
    background: #7544B2;
    padding: 20px 30px;
    border-radius: 20px;
    transition: all 500ms ease-in-out;
    h4
    {
        color: #FFF;
     
    }

    h1 {
        color: #FFF;
    
    }
}
.user-leaderboard-con:hover
{
    background: #ffffff25;
    padding: 20px 30px;
    border-radius: 20px;
    transition: all 100ms ease-in-out;

 
}

.leaders-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    justify-items: center;
  }