.header {
    position: fixed;
    top: 0;
    display: flex;
   
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
    backdrop-filter: blur(0px);
    left: 0;
    width: 100%;
    z-index: 10000;
    /* Ensure header is on top of all content */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent);
    transition: all 200ms linear;
}

.headerScrolled {
    /* Additional styles for scrolled header */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), transparent);
    backdrop-filter: blur(10px);
    transition: all 200ms linear;
}

.logo {
    padding-left: 40px;
    transition: all 0.3s ease-in-out;
}

.logo:hover {
    padding-left: 40px;
    opacity: 0.7;
    scale: 0.95;
}


.notify-icon {
    height: 50px;
    margin-right: 10px;
    margin-left: 0px;
    cursor: pointer;
    transition: all 200ms ease-in-out;

}

.notify-icon:hover {
    height: 50px;
    margin-right: 10px;
    margin-left: 0px;
    scale: 0.9;

}


.text-buttons-header {
    transition: all 200ms ease-in-out;
}


.text-buttons-header:hover {
    scale: 0.9;

}

@media (max-width: 720px) {
    .logo {
        position: relative;
        width: 100%;
        padding-left: 0px;
        height: 50px;
        margin-left: 20px;

    }

}

.rightContainer {
    margin-right: 40px;
    margin-left: auto;
    display: flex;
    gap: 0px;
    align-items: center;
}


.accountImage {
    width: 50px;
    height: 50px;


    /* Align to the right */
    background-color: #ffffff1c;
    color: white;
    padding: 2px;

    border-radius: 50px;
    border: none;
    cursor: pointer;
    transition: all 200ms linear;

}


.accountImage:hover {
    animation-duration: 0.5s;
    background-color: #ffffff44;
    transition: all 200ms linear;
}

.loginButton {
    margin-right: 40px;
    margin-left: auto;
    /* Align to the right */
    background-color: #ffffff1c;
    color: white;
    padding: 15px 40px;
    border-radius: 40px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 1em;
    font-family: 'Montserrat', sans-serif;
    justify-content: center;
    transition: background-color 200ms linear;
    text-decoration: none;
    /* Add margin to separate the button from the text */
}

.loginButton:hover {
    animation-duration: 0.5s;
    background-color: #ffffff44;
    transition: background-color 200ms linear;
}


.searchBar {
    display: flex;
    position: absolute;
    top: 50%; /* Vertical centering */
    left: 52%; /* Horizontal centering */
    transform: translate(-50%, -50%); /* Moves the element back by half its own width and height */
}

.searchBarMobile {
    position: relative;
    justify-content: center;

}

.searchBar input[type="text"] {
    width: 390px; /* Adjust width as needed */
    margin: 0px;

    border: none;
    background: rgba(255, 255, 255, 0.13);
    color: white;
    border-radius: 20px;

    transition: all 0.2s ease-in-out;
    padding: 10px 20px;
    outline: none;
}

.searchBar input[type="text"]:focus {
    box-shadow: 0 4px 60px rgba(0, 0, 0, 0.5);
}


/* Set placeholder color to white */
.searchBar input[type="text"]::placeholder {
    color: rgba(255, 255, 255, 0.678);
    opacity: 1; /* Ensure opacity is set to 1 */
}

.search-result-text {
    margin: 0px;
    flex: 1;
}

.search-rating {


    background: #38383879;
    margin: 0;

    backdrop-filter: blur(8px);
    color: white;
    padding: 5px 10px;
    border-radius: 100px;
    width: 48px;
    text-align: center;

}

.home-menu-header.hidden
{
    opacity: 0;
    pointer-events: none; 
    scale: 0.96;
    transform: translateY(10%);
}

.header-prem
{
    display: flex;
    flex-direction: row;
    border-radius: 60px;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
    margin: 0px;
    gap: 10px;
    background: linear-gradient(90deg, #812ee0, #5829db);

    transition: all 0.2s ease-in-out;
    img {
        height: 25px;
        
    }

    h5 {
        margin: 0px;
        margin-right: 10px;
        font-size: 15px;
    }

}

.header-prem:hover
{
   scale: 1.05;
}

.home-menu-header {
    display: flex;
    flex-direction: column;
    background: #1b1b1b;
    left: 30px;
    top: 90px;
    border-radius: 25px;
    padding: 10px 10px;
 
    gap: 8px;
    position: fixed;
    transition: all 0.2s ease-in-out;
  
    h3 {
      margin: 0px;
      margin-top: 10px;
    }
  
    .footerButton {
      width: 200px;
      margin: 0px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .menu-item
    {
        background: #ffffff08;
    }

    .menu-item:hover
    {
        background: #ffffff14;
    }
  
    .fast-link {
      width: 200px;
      color: #ffffff86;
      transition: all 0.2s ease-in-out;
    }
  
    .fast-link:hover {
      color: #954eec;
    }
  }

.search-result {

    display: flex;
    justify-content: space-between; /* This will push the button to the right */
    align-items: center;
    border-radius: 10px;
    color: white;
    font-weight: 600;
    padding: 8px 20px;
    overflow: hidden;
    width: 88%;
    font-family: 'Montserrat', sans-serif;
    background: rgba(105, 105, 105, 0);
    transition: all 200ms linear;
    cursor: pointer;
}

.search-result:hover {


    background: rgba(105, 105, 105, 0.384);

    transition: all 200ms linear;
}

.searchResultsBox {
    position: absolute;
    top: calc(100% + 10px);
    padding: 12px 0;
    width: 100%;
    background: rgba(31, 31, 31, 0.856);
    border-radius: 20px;

    z-index: 9000;
    /* Adjust the blur value as needed */
}

.search-rating.premium {

    background: #ffd900c2;
    color: black;

}

.search-rating.good {

    background: #3ba100c2;


}

@media (max-width: 720px) {
    .headerContainer {
        position: relative;
    }

    .searchBar {
        position: relative;


    }

    .searchBar input[type="text"] {
        width: 90%;
        background: rgba(53, 53, 53, 0.95);

        border-radius: 10px;
        padding: 12px 0px;
        border: none;
        margin: 0px;

    }

    /* Set placeholder color to white */

}
