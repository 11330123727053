/* Tabs Styles */
.profile-settings {
    display: flex;
   
    padding: 120px 40px
}

.tabs {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.tabs button {
    background-color: #1B1B1B;
    color: #FFFFFF;
    border: none;
    padding: 15px 20px;
    margin-bottom: 5px;
    cursor: pointer;
    text-align: left;
    width: 200px;
    border-radius: 20px;
    
    transition: all 0.3s ease;
}

.tabs button.active {
    background-color: #ffffff;
    color: #1B1B1B;
    
}

.tabs button:hover {
    background-color: #383838;
    color: #ffffff;
    scale: 0.95;
}

.tabs button.active:hover {
    background-color: #ffffff;
    color: #1B1B1B;
}

.tab-content-wrapper {
    flex: 1;
}

.tab-content {
    background-color: #161616;
    padding: 30px;
    border-radius: 20px;
}

.tab-content h2, .tab-content h3 {
    margin-top: 0;
}

.tab-content label {
    display: block;

    margin: 5px 0;
}

.tab-content input {
    width: 80%;
    max-width: 350px;
    padding: 8px;
    margin-bottom: 15px;
  
}



.profile-frame {
    display: flex;
    align-items: center;
}

.profile-frame img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.profile-frame button {
    padding: 12px 15px;
    background-color: #252525;
    color: #FFFFFF;
    border: none;
    border-radius: 20px;
    margin: 20px 0px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.profile-frame button:hover {
    background-color: #363636;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .profile-settings {
        flex-direction: column;
    }

    .profile-settings {
        display: flex;
       
        padding: 120px 10px
    }
    
    .tabs {
        flex-direction: row;
        overflow-x: auto;
        margin-bottom: 20px;
    }

    .tabs button {
        width: auto;
        flex: 1;
        margin-right: 5px;
    }

    .tab-content-wrapper {
        padding: 0 10px;
    }
}

.horizontal-container-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    text-align: left;
    margin: 0;
    margin-top: 20px;
    padding: 0px;
    gap: 10px;
  
    input {
        width: 0;
        margin: 0;
        display: none;
    }

    label
    {
        margin: 0;
    }
  }