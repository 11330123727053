/* admin-page.css */


.review-admin {
 

    gap: 20px;


    /* <--- Set a maximum width */
}


.flex {
    display: flex;
    margin-left: auto;
}

.user-admin
{
    display: flex;
    gap: 20px;
    margin-bottom: 4px;
    background: #ffffff0e;
    padding: 10px;
    border-radius: 20px;
}


.user-admin.ban
{
    display: flex;
    gap: 20px;
    margin-bottom: 4px;
    background: #d81b1b9f;
    padding: 10px;
    border-radius: 20px;
}

.card {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
}

.image-container {
    width: 200px;
    /* Adjust according to your design */
    height: 150px;
    /* Adjust according to your design */
    background: #313131;
    border-radius: 20px;
    overflow: hidden;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* This will make the image cover the entire container */
    object-position: center;
    /* This will ensure the image is centered */

    opacity: 0.5;
}

.card-details {
    margin-left: 20px;
    /* Adjust according to your design */
}

.card-details p {
    margin: 5px 0;
}


.admin-page {
    display: flex;
    flex: 1;
    padding: 20px;
    margin-top: 100px;
    padding: 20px 60px;

}

::-webkit-scrollbar {
    display: auto;
}

.thumb-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* This will make the image cover the entire container */
    object-position: center;
    /* This will ensure the image is centered */
    width: 150px;
    height: 200px;
    border-radius: 30px;
}

.anime-list-container {
    max-height: 60vh;
    max-width: 20vw;
    /* Set your desired height */
    overflow-y: auto;
}

.anime-list-container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.admin-input {}


.anime-list {

    margin: 20px;
}

.anime-list h2 {
    font-size: 20px;
}

.anime-list ul {
    list-style: none;
    padding: 0;
}

.anime-list li {
    color: white;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    border-radius: 10px;
    margin-top: 10px;
    background: #ffffff33;
    font-weight: 600;


    padding: 12px 15px;
    font-size: 16px;
}

.anime-details {
    flex: 3;
}

.anime-details h2 {
    font-size: 20px;
    margin-bottom: 10px;
}