.profile-pic-container {
  position: relative;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.profile-pic-container:hover {
    opacity: 0.8;
    scale: 0.95;
}


.profile-pic {
  width: 50px;
  height: 50px;
  background-color: #ffffff1c;
  color: white;
  padding: 2px;
  border-radius: 50px;
  opacity: 0;
  border: none;
  cursor: pointer;
  transition: all 200ms linear;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  object-fit: cover;
  transition: all 0.3s ease-in;
  
}


.profile-pic.big {
  width: 200px;
  height: 200px;

  border-radius: 200px;
}


.profile-pic.loaded {
  opacity: 1;
 
  transition: all 0.3s ease-in;
  background-color: #ffffff44;
}


.profile-pic.loaded:hover {
  opacity: 1;
  transition: all 0.3s ease-in;
  background-color: #ffffff44;
}




.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateX(-11%) translateY(-13%);
  width: 130%;
  height: 130%;
  object-fit: cover;


}

.online-indicator {
  position: absolute;
  top: 40px;
 
  right: 1px;
  width: 15px;
  height: 15px;
  background-color: #b152ff;
  backdrop-filter: blur(5px);
  border-radius: 50%;
  z-index: 100;
}

.online-indicator.big {

  width: 40px;
  height: 40px;
  top: 160px;
  right: 10px;

 
}


.overlay.big {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translateX(-15%) translateY(-15%);
  width: 290px;
  height: 290px;
  object-fit: cover;

}