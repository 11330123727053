.frame-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%; /* Full viewport height */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  box-sizing: border-box;
  
}


.frame-popup {
  position: relative;
  background-color: #111111;
  padding: 0px 40px;
  height: 100vh;
  width: 100vw;
 
  border-radius: 20px;

  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  h1 {
    padding-top: 100px;
  }
}

.frame-popup input[type="file"] {
  margin-bottom: 10px;
}

.frame-popup .content {
  display: flex;
  flex-direction: column;

  
  align-items: flex-start;
}

.frame-popup .buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.frame-card {
  position: relative; /* Ensure child elements like frame-image-back are positioned relative to this container */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  overflow: hidden; /* Clip the child elements that overflow */
  padding: 12px;
  width: 300px;
  background: #ffffff1f;
  border-radius: 20px;
  max-height: 100px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.frame-image-back {
  position: absolute; /* Makes it position relative to the .frame-card container */
  top: 0;
  left: 30%;
  width: 100%; /* Cover the full width of .frame-card */
  height: 100%; /* Cover the full height of .frame-card */
  object-fit: cover; /* Ensures the image scales correctly */
  filter: blur(30px);
  scale: 1.5;

  z-index: 0; /* Places it behind other content within .frame-card */
  opacity: 1; /* Optional: Adjust opacity for a subtle background */
}

.frame-card:hover {
  opacity: 0.8;
  scale: 0.99;
}

.frame-card.selected-frame {

  background: #7544B2;
  overflow: hidden;
  transition: all 0.3s ease;
}

.frames-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr)); /* Dynamic columns */
  gap: 10px;
  max-width: 100vw;
  max-height: 80%;
  overflow-y: auto;
  height: auto;
  margin: 0px;
}


.frame-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}



.frame-image {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  object-fit: cover;
  z-index: 2;
}

.frame-info {
  flex: 1;
  z-index: 2;
  text-align: left;
  font-size: 16px;
}

.frame-info h4 {
  margin: 0;

  font-size: 18px;
  color: white;
}

.frame-info p {
  margin: 0;
  padding-top: 10px;
  color: white;
}

.popup2-container {
  position: fixed;
  top: 50%;
  width: 30vw;
  max-width: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(36, 36, 36);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 20px;
  z-index: 1000;
}

.popup2 {
  text-align: center;
}

.close-popup2-button {
  margin-top: 10px;
  padding: 12px 16px;
  background: #424242;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

.close-popup2-button:hover {
  background: #2c2c2c;
}
