/* Import Montserrat font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.app {
  position: relative;
 
  overflow-x: hidden;

}

::-webkit-scrollbar {
  display: none;
}

.privacy {
  padding: 0 50px;
  text-align: center;
  font-size: 14px;
  color: #ffffff9d;
}


.tasty-title {


  font-size: 42px;
  margin-bottom: 14px;
  margin-top: 0px;
  color: white;
}

.tasty-underline {
  background: #9145ec;
  border-radius: 100px;
  margin-bottom: 40px;
  margin-top: 0p;
  width: 100px;
  height: 10px;
}



body {

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  background: black;
  margin: 0;
  /* Reset default body margin */
  padding: 0;
  /* Reset default body padding */
}


.content {
  background: #111111;
  padding: 0px 0px;
 
  margin: 0px;
}


a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {

  font-family: 'Montserrat', sans-serif;
  color: white;
  text-decoration: none;
  z-index: 500;
  /* Apply Montserrat font to headings */
}


.content.article {

  padding: 120px 40px;
  min-height: 70vh;


}

@media (max-width: 720px) {


  .content.article {

    padding: 120px 20px;
    min-height: 30vh;


  }
}


.text {
  text-align: left;
  color: white;

  font-family: 'Montserrat', sans-serif;
}



.title {
  font-size: 2.5em;
  color: white;
  /* Make the title bigger */
}

.text p {
  font-size: 1.5em;
  color: white;
  /* Make the text bigger */
}

.loading-con
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

textarea {
  color: white;
  resize: both;
  width: 400px;
  height: 200px;
  /* Adjust input width as needed */
  margin: 15px 6px;
  padding: 14px 20px;
  background: #111111;
  border-radius: 20px;
  font-weight: 600;
  font-size: 1em;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  border: 2px solid rgba(204, 204, 204, 0.295);
  transition: all 200ms ease-in-out;
}


select {
  color: white;
  width: 300px;
  /* Adjust width as needed */

  padding: 14px 20px;
  background: #111111;
  border-radius: 20px;
  font-weight: 600;
  font-size: 1em;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin: 0;
  border: none;
  transition: all 200ms ease-in-out;
}

/* Optionally, style the dropdown arrow */
select::-ms-expand {
  display: none;
}

select::-webkit-scrollbar {
  width: 8px;
}

select::-webkit-scrollbar-thumb {
  background-color: rgba(204, 204, 204, 0.295);
  border-radius: 10px;
}

select:focus {
  outline: none;
}


input {
  color: white;
  width: 300px;
  /* Adjust input width as needed */
  margin: 15px 6px;

  padding: 14px 20px;
  background: #111111;
  border-radius: 20px;
  font-weight: 600;
  font-size: 1em;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  border: 2px solid rgba(204, 204, 204, 0.295);
  transition: all 200ms ease-in-out;
}

input:focus {
  background: #1B1B1B;
  outline-width: 0;
  transition: all 200ms ease-in-out;
  border: 2px solid #7544B2;
}

textarea:focus {
  background: #1B1B1B;
  outline-width: 0;
  transition: all 200ms ease-in-out;
  border: 2px solid #7544B2;
}



.star-selected {
  color: #ffc107; 
  font-size: 40px;
}

.star-unselected {
  color: #ccc;
  font-size: 40px;
}
.leave-review-container
{
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Default flex behavior */
  button {

  }



  form {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Default flex behavior */


    
    textarea {
      margin: 10px 0;
      
      width: auto;
    }
  }
}
button {

  background-color: #7544B2;
  color: white;
  padding: 15px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1em;
  font-family: 'Montserrat', sans-serif;
  margin: 5px 0px;
  /* Add margin to separate the button from the text */
  transition: all 200ms ease-in-out;
  
}
.close-icon-popup {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1000;
  cursor: pointer;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
}

.close-icon-popup:hover {

  color: rgba(255, 255, 255, 1);
}
/* Disable image dragging */
img {
  -webkit-user-drag: none;
  /* For iOS and Safari */
  user-drag: none;
  /* For Chrome, Firefox, and Opera */
  user-select: none;
  /* For Internet Explorer */
}

* {
  -webkit-tap-highlight-color: transparent;
  /* For iOS */
  -webkit-touch-callout: none;
  /* For iOS */
  -moz-user-select: none;
  /* For Firefox */
  -ms-user-select: none;
  /* For Internet Explorer */
  user-select: none;
  /* For Chrome, Safari, and Opera */
}

button:hover {


  transition: all 200ms ease-in-out;

  background-color: #8f4ae4;

}

.button-sec {
  background-color: #ffffff1f;
  color: white;
  padding: 15px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1em;
  font-family: 'Montserrat', sans-serif;
  margin: 5px 0px;
  /* Add margin to separate the button from the text */
  transition: all 200ms ease-in-out;

}

.button-sec:hover {


  transition: all 200ms ease-in-out;
  scale: 0.985;
  background-color: #ffffff11;

}

.popup-container {
  position: fixed;
  top: 0;
  z-index: 8;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: #111111;
  padding: 20px 40px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.popup input[type="file"] {
  margin-bottom: 10px;
}

.popup .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.popup .buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.actionButton {
  background-color: #7544B2;
  color: white;
  padding: 15px 40px;
  border-radius: 40px;
  border: none;
  text-decoration: none;

  display: inline-flex;
  margin-top: 20px;

  cursor: pointer;
  font-weight: bold;
  font-size: 1em;
  font-family: 'Montserrat', sans-serif;

  /* Add margin to separate the button from the text */
  transition: all 200ms ease-in-out;

}

.actionButton:hover {


  transition: all 200ms ease-in-out;

  background-color: #8f4ae4;
  scale: 1.05;
}



.buttonsapp
{
  position: absolute;
  bottom: 0;
  padding-bottom: 20%;
  width: 100%;
  background: #1B1B1B;
  display: flex;
  flex-direction: column;

 
}

label {
  color: white;

  font-family: 'Montserrat', sans-serif;
}

.label-switch-text {
  display: flex;
  margin-left: 12px;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  text-align: left;

}

.horizontal-container {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  padding: 0px;
  gap: 10px;

}

.label-switch {
  cursor: pointer;
  text-indent: 70px;

  width: 50px;
  height: 30px;
  padding: 0px;
  margin: 0px;

  background: grey;
  line-height: 27px;
  display: flex;
  border-radius: 100px;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.label-switch:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 5px;
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked+.label-switch {
  background: #8f4ae4;

}

input:checked+.label-switch:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.label-switch:active:after {
  width: 30px;

}

#garland {
  position: fixed;
  top: 0;
  left: 0;
  background-image: url('../src/assets/christmas.png');
  height: 36px;
  width: 100%;
  overflow: hidden;
  z-index: 99000
  }
   
  #nums_1 {
  padding: 100px
  }
   
  .garland_1 {
  background-position: 0 0
  }
   
  .garland_2 {
  background-position: 0 -36px
  }
   
  .garland_3 {
  background-position: 0 -72px
  }
   
  .garland_4 {
  background-position: 0 -108px
  }

  .show-ny {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%; /* Full width at the bottom */
    padding: 15px 0;
    background: linear-gradient(90deg, #6a0dad, #9934e1); /* Darker purple gradient */
    color: #fff; /* Text color */
    font-weight: bold; /* Bold font */
    font-size: 16px; /* Font size */
    text-align: center;
    text-transform: uppercase;
    border: none; /* No borders */
    cursor: pointer; /* Pointer cursor */
    z-index: 800; /* Stay on top */
    animation: gradientAnimation 5s infinite, bounce 2s infinite; /* Gradient and bounce animations */
  }
  
  /* Gradient animation */
  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  /* Bounce effect for subtle up and down movement */
  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(5px); } /* Slight upward movement */
  }
  
  .show-ny:hover {
    background: linear-gradient(90deg, #4b0082, #a11eff); /* Reverse gradient on hover */
    animation: gradientAnimation 3s infinite, pulse 1.5s infinite; /* Faster animation on hover */
  }
  
  /* Pulse effect for hover */
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); } /* Slight scale up */
    100% { transform: scale(1); }
  }
  
  /* Ensure smooth gradient animation */
  .down-bt {
    background-size: 200% 200%;
  }
  

  .down-bt {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    left: 0;
    width: 100%; /* Full width at the bottom */
    padding: 15px 0;
    background: linear-gradient(90deg, #ad0d85, #e1345f); /* Darker purple gradient */
    color: #fff; /* Text color */
    font-weight: bold; /* Bold font */
    font-size: 16px; /* Font size */
    text-align: center;
    text-transform: uppercase;
    border: none; /* No borders */
    cursor: pointer; /* Pointer cursor */
    z-index: 8000; /* Stay on top */
    animation: gradientAnimation 5s infinite, bounce 2s infinite; /* Gradient and bounce animations */

    img {
      height: 50px;
    }
  }

  .down-bt:hover {
  
    animation: gradientAnimation 3s infinite, pulse 1.5s infinite; /* Faster animation on hover */
  }

  
  /* Ensure smooth gradient animation */
  .down-bt {
    background-size: 200% 200%;
  }
  