.review {
    background: #212121;
    margin: 20px 0px;
    padding: 8px 20px;
    border-radius: 35px;
    position: relative;
    padding-bottom: 15px;
    overflow: hidden;


    transition: all 200ms ease-in-out;
    max-width: 500px;

    div
    {
        position: relative;
        z-index: 1;
    }
    /* <--- Set a maximum width */
}
.review.deleted {
   opacity: 0.6;
   scale: 0.95;
    /* <--- Set a maximum width */
}


.review-con {
    display: flex;
    padding-top: 10px;
    div
    {
        position: relative;
        z-index: 1;
    }
}


.review.sub {
  
    background: #202020;
}

.review-date {
    display: flex;
  
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 1;
    color: rgba(255, 255, 255, 0.521);
}

.star-con {
    background: #ffffff1c;
    padding: 0px 15px;
    border-radius: 25px;
    text-align: center;
    margin: 0px;
    margin-right: 10px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.star {
    font-size: 18px;
    color: white;
    font-weight: 700;
    margin-right: 6px;
}


.stars-count {
    font-size: 16px;
    color: #ccc;
    color: white;
    font-weight: 700;
}

.star-selected {
    color: #9e5eec;
    /* yellow */
}

.star-unselected {
    color: #cccccc71;
    /* gray */
}

.review-message {
    font-size: 16px;
    font-weight: 900;
    text-align: left;
    word-break: break-word;
    text-overflow: ellipsis;
    position: relative;
    z-index: 1;
}

.review-p {
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
    padding: 0px;
    text-align: left;
}

.review-user-section {
    display: flex;
    font-size: 14px;
    text-align: center;
    font-weight: 900;
    gap: 12px;
    position: relative;
    z-index: 1;
    margin: 0px;
    padding: 0px;
    align-content: center;
    align-items: center;
}