.profile-container {
  position: relative;
  display: inline-block;
 
}

.profile-popup.gone {
  opacity: 0;
  visibility: hidden;
}

.profile-popup {
  display: block;
  position: absolute;
  top: 100%;
  right: 2%;
  background-color: #1d1d1d;
  color: white;
  padding: 0px 10px;
  opacity: 1;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
}

.profile-popup ul {
  list-style: none;
  margin: 0;
  padding: 10px 0;
}

.profile-popup li:hover {
  background: #ffffff11;
}

.profile-popup li {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin: 5px 0px;

  border-radius: 15px;
  transition: all 0.3s ease;

}

.profile-popup p {
  width: 100%;
  display: inline-flex;
  /* Inline-flex to keep it inline with other elements */
  align-items: center;
  /* Center vertically */

  padding: 0;
  /* Reset padding */
  margin: 0px;
}

.profile-popup li .icon {
  width: 20px;
  /* Adjust the size as necessary */
  height: 20px;
  /* Adjust the size as necessary */
  margin-right: 10px;
}

.profile-popup li a:hover {
  text-decoration: none;
}

.profile-popup li a {
  color: white;
  align-items: center;
  /* Center vertically */
  justify-content: center;
  /* Center horizontally */
  display: flex;
  /* Flex container for centering */
  align-items: center;
  /* Center vertically */
  justify-content: center;

  padding: 0px;
  text-decoration: none;
}