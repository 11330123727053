.yearly-stats {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 900;
    padding: 16px;
  }

  .story-container {
    position: relative;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    aspect-ratio: 9/16;
    border-radius: 22px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .purple-blue { background: linear-gradient(135deg, #9333ea, #3b82f6); }
  .pink-orange { background: linear-gradient(135deg, #ec4899, #f97316); }
  .green-teal { background: linear-gradient(135deg, #22c55e, #14b8a6); }
  .blue-indigo { background: linear-gradient(135deg, #3b82f6, #6366f1); }
  .violet-purple { background: linear-gradient(135deg, #8b5cf6, #9333ea); }
  .purple-pink { background: linear-gradient(135deg, #9333ea, #ec4899); }

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    padding: 4px;
    display: flex;
    gap: 4px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .progress-segment {
    flex: 1;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color 0.3s ease;
  }

  .progress-segment.active {
    background-color: white;
  }

  .content-ny {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    text-align: center;
    color: white;
  }

  .content-ny.fade-in {
    animation: fadeIn 0.5s ease forwards;
  }

  .content-ny.slide-out {
    animation: slideOut 0.3s ease forwards;
  }

  .title-ny {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .description-ny {
    font-size: 18px;
    margin-bottom: 32px;
    opacity: 0.9;
  }

  .highlight {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 32px;
    animation: pulse 2s infinite;
  }

  .navigation {
    position: absolute;
    bottom: 24px;
    left: 0;
    width: 85%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 24px;
  }

  .nav-button {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-button:hover:not(.disabled) {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .nav-button.disabled {
    opacity: 0;
    cursor: default;
  }

  .share-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 9999px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.3s ease;
  }

  .share-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
    transform: scale(1.05);
  }

  .share-icon {
    width: 20px;
    height: 20px;
    position: relative;
  }

  .share-icon::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border: 2px solid white;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .share-icon::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 8px;
    background-color: white;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -100%);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideOut {
    to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  @media (max-width: 480px) {
    .story-container {
      max-width: 100%;
    }
    
    .title-ny {
      font-size: 20px;
    }
    
    .description-ny {
      font-size: 16px;
    }
    
    .highlight {
      font-size: 32px;
    }
  }