

.time-watch-card {
    position: absolute;
    top: 8px;

    right: 8px;
    font-size: 14px;
 
    margin: 0;
    color: white;
    padding: 5px 10px;
   
    border-radius: 20px;
    backdrop-filter: blur(10px);
    background-color: #11111152;
   
    transition: all 0.3s ease;
}

.watch-card-text-block {
    display: flex;
    align-items: flex-start;
    bottom: 10px;
    left: 5px;
}

.series-name {
    font-size: 18px;
    margin: 10px;
    padding: 0px;
    border-radius: 0;
    color: white;
    width: 300px; /* Add a width to limit the series name */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Display ellipsis (...) for overflow */
    white-space: nowrap; /* Prevent text wrapping */
}

.series-name.preview {
    font-size: 15px;
    margin: 10px;

    padding: 0px;
    border-radius: 0;
    color: white;
    text-align: left;
    width: 100%; /* Add a width to limit the series name */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Display ellipsis (...) for overflow */
    white-space: nowrap; /* Prevent text wrapping */
}

.series-subname {
    font-size: 14px;
    opacity: 0;
    margin: 10px;
    color: white;
    padding: 0px;
    transition: all 0.3s ease;
}


.series-subname.active {
    opacity: 1;
    scale: 1;
    transition: all 0.3s ease;
}


.series-thumbnail {
    opacity: 0;

    width: 100%;
    height: 100%;
    background: #111111;
    transition: all 0.3s ease;
    scale: 1;
}

.series-thumbnail.loaded {
    opacity: 0.6;
    /* Display image after animation */
}

.series-thumbnail.current {
    opacity: 0.1;
    background: black;

    scale: 1.2;

    transition: all 0.3s ease;
    /* Add transition property */
}


.series-thumbnail.hovered {
    opacity: 0.2;
    background: black;
    filter: blur(3px);
    scale: 1.2;

    transition: all 0.3s ease;
    /* Add transition property */
}

.watch-card {
    position: relative;
    width: 340px;
    height: 200px;
    border-radius: 20px;
    cursor: pointer;
    overflow: hidden;

    margin: 0 10px;
    transition: all 0.3s ease;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.5);
    flex: 0 0 auto;
    /* Fix the size of the elements */
    background: rgb(41, 41, 41);
}


.watch-card.preview {
    position: relative;
    width: 240px;
    height: 140px;
    border-radius: 20px;
    cursor: pointer;
    overflow: hidden;

    margin: 0 10px;
    transition: all 0.3s ease;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
    flex: 0 0 auto;
    /* Fix the size of the elements */
    background: rgb(41, 41, 41);
}


.watch-card:hover {
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.1);
    background: black;
    transform: translateY(5px)
}