.landing-content {
  position: relative;
  height: 70vh;
  padding: 20px 60px;
  display: flex;
  align-items: center;

  background: #111111;
  justify-content: flex-start;
}


.anime-title {
  margin-top: 0px;
  align-items: center;
  justify-items: center;

  display: flex;
  align-content: center;
  gap: 15px;
  transition: all 0.2s ease-in-out;
}

.mind-icon {
  background: white;
  border-radius: 30px;
  padding: 5px;
}

.tip-label {
  opacity: 0.7;

}

.anime-title.tip {
  margin-top: 15px;
}

.scroll-button {
  background: #ffffff15;
  border: none;
  font-size: 2rem;
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  top: 50%;
  border-radius: 100px;
  backdrop-filter: blur(30px);
  transform: translateY(-80%);
  z-index: 1000;
  color: white;
  /* Adjust color as needed */

  img {
    padding: 0px;
    margin: 0px;
  }
}

.scroll-button:hover {
  scale: 0.99;
  background: #ffffff34;
}

.scroll-button.left {
  left: 0;
  transform: translateY(-80%) rotate(180deg);
  /* Rotate 180 degrees */
}

.scroll-button.right {
  right: 0;
}

.anime-line-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}



.anime-title:hover {
  scale: 0.99;
  opacity: 0.7;
}



@media screen and (max-width: 720px) {
  .animeblock {

    padding: 20px 0;
    padding-left: 20px;
    margin: 0px;
  }

  .anime-title {
    font-size: 24px;
  }

  .anime-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    grid-gap: 20px;
    justify-items: center;
  }

  .landing-content {

    height: 60vh;

    padding: 20px 30px;
    display: flex;
    align-items: center;
    padding-top: 100px;
    background: #111111;
    justify-content: flex-start;

    h1 {
      font-size: 28px;
      z-index: 10;
    }

    p {
      font-size: 20px;
      color: #fff;

    }
  }

}

.neuro-recommendation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  /* Adjusts width to fit content */
  min-width: 200px;
  /* Set your desired minimum width here */
  max-width: 100%;
  /* Ensures it doesn't overflow the container */
  border-radius: 50px;
  padding: 10px 20px;
  font-family: Arial, sans-serif;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.neuro-recommendation-icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.neuro-recommendation-text {
  font-size: 16px;
  color: #111111;
  font-weight: bold;
}


@media screen and (min-width: 721px) {
  .anime-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    grid-gap: 20px;
    justify-items: center;

  }

}

.landing-text {
  z-index: 20;
}



.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  background: #111111;
  background-color: #111111;
  border-radius: 0 0 60px 60px;

  width: 100%;
  height: 100%;
  object-fit: cover;
}


.telegram-banner {
  position: fixed;
  bottom: 20px;
  z-index: 29;
  padding: 10px 20px;
  border-radius: 20px;
  right: 20px;
  text-align: center;
  backdrop-filter: blur(6px);
  background-color: #222222b7;
}

.app-promo {
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  height: 100vh;
  left: 0;

  z-index: 10000;

  background: #111111;
  text-align: center;

  right: 0;

  img {
    /* Make the image fill the container */
    width: 100%;
    /* or use 'height: 100%;' if that's what you prefer */
    height: auto;
    border-radius: 20px;



  }

  .button-sec {
    margin: 0px 20px;
    margin-top: 20px;
    border-radius: 15px;
  }

  .actionButton {
    margin: 10px 20px;
    display: block;
    border-radius: 15px;

    background: linear-gradient(135deg, #7c41c5, #5518a0);
  }
}


.container-wrapper {
  position: relative;
  margin: 0px 0;



  /* Add some margin to create space for the button */
}

.category-title {
  margin-top: 10px;
}

.anime-line-container {
  display: flex;
  overflow-x: auto;
  padding-bottom: 20px;
  scrollbar-width: none;
  position: relative;
  flex-wrap: nowrap;
  /* Prevent wrapping */
}

.anime-line-container-padding {
  display: flex;
  overflow-x: auto;

  position: relative;
  flex-wrap: nowrap;
  /* Prevent wrapping */
}

.view-all-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
  z-index: 4000;
  font-weight: bold;
  display: flex;
  /* Use flexbox */
  align-items: center;
  /* Vertically center */
  padding: 0;
  padding-left: 60px;
  padding-right: 10px;
  text-decoration: none;
  padding-bottom: 50px;
  cursor: pointer;
  text-align: center;
  margin: 0;
  background: linear-gradient(to left, #111111, transparent);
  transition: all 200ms ease-out;
}

.view-all-button:hover {
  background: linear-gradient(to left, #111111, transparent);

  padding-left: 300px;
  padding-right: 20px;

  transition: all 200ms ease-in;


}



.watch-container {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 40px;

  justify-items: center;

}

.friends-container {
  display: flex;
  margin: 20px 0;
  flex-direction: column;
  gap: 10px;
}

.friend {
  display: flex;
  flex-direction: row;
  gap: 20px;
  color: white;
}


.home-main-hor-con {
  display: flex;
  flex-direction: row;

  padding: 40px;
  padding-right: 0;

  padding-top: 120px;
}

.home-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;

  h3 {
    margin: 0px;
    margin-top: 10px;

  }

  .footerButton {
    width: 200px;
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .fast-link {
    width: 200px;
    color: #ffffff86;
    transition: all 0.2s ease-in-out;
  }

  .fast-link:hover {
    color: #954eec;
  }
}

.menu-item {
  display: flex;
  flex-direction: row;
  border-radius: 20px;

  width: 200px;
  height: 50px;
  padding: 0px 10px;
  padding-right: 25px;
  cursor: pointer;
  background: #ffffff09;
  align-items: center;
  transition: all 0.2s ease-in-out;


  a {
    margin-left: 10px;
    font-weight: 600;
  }

  img {
    height: 50px;
  }
}

.menu-item:hover {
  scale: 0.98;

  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.feed {
  overflow-x: scroll;
 
  margin: 0 0px;
  display: flex;
  flex-direction: column;
  padding-left: 260px;
}


@media (max-width: 720px) {


  .feed {

    padding-left: 0px;
  }

  .home-player-top {
    margin: 0 10px;

  }
}


.home-player-top {
  margin-right: 30px;
  border-radius: 30px;
  height: 40vh;

  background: #5518a0;
  position: relative;
  /* This allows the video to position itself within the container */
  overflow: hidden;
  /* Ensures no part of the video will overflow */

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the video covers the container, maintaining aspect ratio */
    position: absolute;
    top: 0;
    left: 0;
  }

  .home-overlay {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    border-radius: 30px;
    display: flex;


    align-items: flex-end;
    /* Align content to the bottom */
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 100%);


  }

  .playButton.head {
    position: absolute;
    right: 30px;
    margin-bottom: 30px;
  }

  .home-title {
    display: flex;
    flex-direction: column;
    padding: 20px 40px;

    h1 {
      margin: 0px;
    }

    p {
      font-size: 20px;
    }


  }
}

@media (max-width: 720px) {
  .container-wrapper {

    margin: 0px 0;
    /* Add some margin to create space for the button */
  }



  .home-overlay {
    align-items: center;

    .actionButton {

      right: 30px;
      margin-bottom: 30px;
    }
  }

  .home-menu {
    display: none;
  }


  .home-title {
    margin-bottom: 100px;


  }

  .home-main-hor-con {

    padding: 0px;
    padding-top: 120px;
  }






}

/* Full-Width Ad Block */
.full-width-da-block {

  position: relative;
  padding: 60px 50px;
  color: rgb(0, 0, 0);
  text-align: left;

  display: flex;
  flex-direction: column;

  align-items: left;
  overflow: hidden;
  border-radius: 40px;
  margin: 10px 0px;
  margin-right: 30px;


  img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
    width: 100%;
    z-index: 0;
    height: 100%;
    object-fit: cover;
    /* Cover the entire block */

  }

  button {
    z-index: 100;
  }

  div {
    z-index: 100;
    display: flex;
    gap: 10px;
  }
}

.theme-selector {
  margin: 0px;
  display: flex;

  align-items: center;

  select {
    width: 90%;
    background: #2222228e;
  }
}

.theme-selector label {
  margin-right: 10px;
  font-weight: bold;
}

#theme-dropdown {
  padding: 5px 20px;
  border-radius: 20px;

}

.da-title {
  font-size: 36px;
  z-index: 10;
  margin-top: 0px;
  font-weight: bold;
  margin-bottom: 10px;
}

.da-text {
  font-size: 20px;
  margin-bottom: 20px;
  max-width: 600px;
  z-index: 10;
  font-weight: 600;
  line-height: 1.5;
}

.gradient-background {
  background: linear-gradient(135deg, #052029, #160f22, #131313, #0e0e0e);
  /* Adjust the gradient colors and angle as needed */
  min-height: 100vh;
  background-size: cover;
  background-attachment: fixed;
}

.da-text2 {
  font-size: 20px;
  margin-bottom: 20px;
  max-width: 600px;
  z-index: 10;
  font-weight: 400;
  line-height: 1.5;
}


.da-button {
  padding: 15px 25px;
  background-color: white;
  color: rgb(43, 43, 43);
  border: none;
  z-index: 10;
  width: 250px;
  z-index: 100;
  border-radius: 20px;
  cursor: pointer;
  margin: 0px;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.da-button:hover {
  background-color: #ffffffe7;
  scale: 0.98;
}

.review-container {

  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
}

.review-header {
  display: flex;
  gap: 30px;
  align-items: center;
}

.rec-anime-name {
  font-size: 18px;
  opacity: 0.8;
  font-weight: bold;
}

.open-anime-button {

  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}


.review-body {
  margin-top: 0px;
  padding: 0px;

  label {
    opacity: 0.3;
  }
}

.anime-stars {
  color: #954eec;
  font-size: 3rem;
  margin: 0px;
}

.rec-review-message {
  margin-top: 0px;
  font-size: 30px;
}