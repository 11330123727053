/* Footer Styles */
.footer {
  background-color: #000000;
  color: #fff;
  padding: 40px 40px;
  z-index: 2;
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;

 

}

.footer p {
  margin: 0;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footerButton {
  background-color: hsla(0, 0%, 100%, 0.1);
  color: white;
  padding: 8px 20px;
  border-radius: 40px;
  border: none;
  text-decoration: none;
  text-align: center;
  align-items: center;  /* Center text vertically */
  margin-right: 10px;

  display: inline-flex;
  margin-top: 20px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1em;
  font-family: 'Montserrat', sans-serif;

  /* Add margin to separate the button from the text */
  transition: all 200ms ease-in-out;

  img {
    height: 30px;
    margin-right: 10px;
  }
}

.footerButton:hover {


  transition: all 200ms ease-in-out;

  background-color: #8f4ae4;
  scale: 1.05;
}


.footer li {
  display:  inline-block;
  margin-right: 20px;
  padding: 10px;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
