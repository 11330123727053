.login-page {
    display: flex;
    height: 100vh;
    background: #111111;
}

.left-section {
    flex: 3;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* Ensure the image doesn't overflow its container */
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
}


.placeholder-image {
    min-width: 100vh;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
}


.checkbox-container {
    display: flex;
    margin: 20px 50px;
    align-items: center;
   
}

.checkbox-container input[type="checkbox"] {
    width: 25px;
    height: 25px;
    margin-right: 8px;
    cursor: pointer;
    background: #8c00ff;
}

.checkbox-container label {
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
    user-select: none; /* Prevents text selection */
}

/* Optional: hover effect */
.checkbox-container input[type="checkbox"]:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

/* Optional: checkbox focus effect */
.checkbox-container input[type="checkbox"]:focus {
    background-color: #8c00ff;
    outline-offset: 2px;
}


/* Additional styles to ensure proper centering and cropping */
.placeholder-container {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;

}

.right-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-logo {
    max-width: 200px;
    /* Adjust logo size as needed */
    margin-bottom: 40px;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.buttons {
    display: flex;
}

.button-login {
    width: 350px;
}

.banner {
    background-color: #c2361d; /* Change this to your desired background color */
    color: #fff; /* Change this to your desired text color */
    padding: 10px 80px; /* Adjust padding as needed */
    text-align: center; /* Center-align text */
    border-radius: 10px;
    font-size: 14px; /* Adjust font size as needed */
    font-weight: bold; /* Adjust font weight as needed */
    width: 200px;
   
}



.button-sec.login {
    width: 350px;
}
