.ad-block-popup-con {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2000;
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;

    height: 100vh;
    background: #242424a4;


    .popup {
        position: relative;
        max-width: 600px;
        align-items: start;
        justify-content: left;
        text-align: left;

        img {
            height: 40px;
        }

   

        .horizontal-container2 {
            display: flex;
            align-content: center;
            margin-top: 15px;
            justify-content: center;
            flex-direction: row;
            gap: 20px;

            a {
                align-content: center;
                justify-content: center;
                margin: 0px;
            
                color: #A066E9;
            }

            .actionButton {
                padding: 15px 20px;
                color: white;
            }
        }
    }
}
.timer {
    position: absolute;
    top: 30px; /* 10px from the top of the popup */
    right: 30px; /* 10px from the right of the popup */
    font-size: 14px;
    color: #c2c2c2;
   
}

.close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.568);
}

