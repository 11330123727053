/* Container Form */
.skip-mark-form {
    font-family: Arial, sans-serif;
    padding: 0 10px;
  }
  
  /* Paragraph styling */
  .skip-mark-form p {
    font-size: 12px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  /* Label styling */
  .skip-mark-form label {
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    margin-right: 10px;
    display: block;
    margin-bottom: 5px;
  }
  
  /* Input and Checkbox styling */
  .skip-mark-form input[type="text"],
  .skip-mark-form select,
  .skip-mark-form input[type="number"] {
    font-size: 14px;
    padding: 2px;
    margin: 0px;
    padding: 0px;
    margin-bottom: 10px;
    width: 100px; /* Adjust as needed */
  }
  
  .skip-mark-form input[type="checkbox"] {
    transform: scale(0.8);
    margin: 0px;
    padding: 0px;
    margin-right: 5px;
  }

  
  /* Button styling */
  .skip-mark-form button {
    font-size: 14px;
    margin: 0px;
    padding: 8px 10px;
    cursor: pointer;
    display: flex;
    background-color: #474747; /* Green background */
    color: white; /* White text */
    border: none;
    margin-right: 10px;
    
    margin-bottom: 20px;
    border-radius: 12px;
  }
  
