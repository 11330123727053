.series-name {
    position: absolute;
    bottom: 10px;

    left: 5px;
    font-size: 18px;
    margin: 0;
    color: white;
    padding: 5px 10px;
    border-radius: 100px;

}

.series-subname {
    position: absolute;
    bottom: 40px;
    scale: 0.9;
    left: 5px;
    font-size: 14px;
    opacity: 0;
    margin: 0;
    color: white;
    padding: 5px 10px;
    border-radius: 100px;
    transition: all 0.3s ease;
}

.series-subname.active {
    opacity: 1;
    scale: 1;
    transition: all 0.3s ease;
}


.series-thumbnail {
    opacity: 0;

    width: 100%;
    height: 100%;
    background: #111111;
    transition: all 0.3s ease;
    scale: 1;
}

.series-thumbnail.loaded {
    opacity: 0.6;
    /* Display image after animation */
}

.series-thumbnail.blur {
    filter: blur(20px);
 
 
}

.series-thumbnail.current {
    opacity: 0.1;
    background: black;

    scale: 1.2;

    transition: all 0.3s ease;
    /* Add transition property */
}


.series-thumbnail:hover {
    opacity: 0.7;
    background: black;
    
   
   

    transition: all 0.3s ease;
    /* Add transition property */
}

.series-card {
    position: relative;
    width: 250px;
    height: 150px;
    border-radius: 20px;
    cursor: pointer;
    overflow: hidden;

    margin: 0px;
    transition: all 0.3s ease;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.5);
    flex: 0 0 auto;
    /* Fix the size of the elements */
    background: rgb(41, 41, 41);
}

.series-card:hover {
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.3);
    background: black;
    transform: translateY(5px)
}