.anime-name {

    margin: 10px 0px;
    font-size: 44px;
}

.anime-text-small {

    white-space: pre-wrap;

    font-size: 18px;
}

.blud-back {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background: #111111;
    background-color: #111111;
    border-radius: 0 0 60px 60px;
    filter: blur(75px);
    scale: 0.95;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease-in-out;

}


.unavailable-con {

    height: 80vh;
 
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;


   
    padding: 0 10%;

    img {
        max-height: 200px;
        border-radius: 40px;
        transition: all 100ms ease-in-out;
    }

    p {
        margin-top: 0px;
        opacity: 0.6;
        max-width: 700px;
        
    }

    img:hover{
        scale: 30;
        filter: blur(10px);
        border-radius: 0;
        z-index: 100000;
        transition: all 10000ms ease-in-out;
        transform: rotate(50deg);
    }

}

@media (max-width: 720px) {


    .unavailable-con {

        padding: 120px 20px;
        min-height: 30vh;


    }
}



.blud-back.loaded {
    opacity: 0.6;
    scale: 1;
}

.anime-text-small-container {
    position: relative;
}

.anime-text-small-desc {
    height: 100px;
    overflow: hidden;
    position: relative;
    transition: all 200ms ease;
}

.anime-text-small-desc.expanded {
    height: auto;
    max-height: none;
    transition: all 200ms ease;
}


.expand-button {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-top: 40px;
    border-radius: 0px;
    z-index: 4000;
    width: 100%;
    background: linear-gradient(to bottom, transparent, #111111);
    border: none;
    height: 100px;
    margin: 0;
    scale: 1;
    font-size: medium;
    cursor: pointer;
    transform: translateY(-100%);
    margin-bottom: -100px;
    /* Adjust this value to match the height of the button */
}

.expand-button:hover {
    background: linear-gradient(to bottom, transparent, #111111);
    padding-top: 50px;
    font-size: 15px;
}

.anime-actions-con {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 10px;
    gap: 10px;

    select {
        color: white;
        width: 220px;
        /* Adjust input width as needed */
        margin: 15px 6px;

        padding: 15px 20px;
        background: #2c2c2c72;
        cursor: pointer;
        border-radius: 90px;
        font-weight: 600;
        color: #ffffffde;
        font-size: 1em;
        font-family: 'Montserrat', sans-serif;
        text-align: center;
        border: 0px solid rgba(204, 204, 204, 0.164);
        transition: all 200ms ease-in-out;
    }

    select:hover {
        scale: 0.98;
    }
}

.actionButton.playButton {
    display: inline-flex;
    align-items: center;
    padding-left: 20px;
    background: #ffffff;
    color: #111111;
    /* Adjust padding as needed */
    font-size: 18px;
    /* Adjust text size as needed */
}

.playIcon {
    width: 35px;
    /* Adjust the width as needed */
    height: 22px;
    /* Adjust the height as needed */
    margin-right: 5px;
    fill: #111111;
    /* Space between icon and text */
}

.announce {
    display: flex; 
    background-color: #3a3a3a;
    width: 100%;
    margin-top: 20px;
    align-content: center;
    align-items: center;
    padding: 15px 20px;
    font-weight: 600;
    border-radius: 20px;
    box-sizing: border-box; /* Add this line */
    a {
        margin-left: 10px;
    }

    
}

.center-content {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.pg-anime-text {

    margin: 0px 0px;

    background: #232323a1;
    width: 50px;

    text-align: center;
    padding: 4px 4px;
    border-radius: 20px;
    font-size: 18px;
}

.pg-anime-text.adult {
    background: #b92d2da1;
    border-color: #ff25254d;
    border-width: 1.5px;
    font-weight: 700;
    border-style: solid;
    /* Add this line */
}

.pg-anime-text.hiq {
    background: #862db9a1;
    border-color: #8325ff4d;
    border-width: 1.5px;
    font-weight: 500;
    width: auto;
    padding: 0 15px;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-style: solid;
    /* Add this line */
}

.pg-anime-text.viral {
    background: #b96c2da1;
    border-color: #ff51254d;
    border-width: 1.5px;
    font-weight: 500;
    width: auto;
    padding: 0 15px;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-style: solid;
    /* Add this line */
}

.watch-count-con
{
    display: flex;
    
    flex-direction: row;
    gap: 10px;
    margin: 0 20px;
    p {
        font-weight: 600;
        opacity: 0.8;
    }
}
.anime-text {

    margin: 10px 0px;


    font-size: 22px;
}

.series-find-input {
    border-radius: 60px;
    width: 80px;
    height: 15px;
    margin: 0px;
    margin-right: 10px;
    -webkit-appearance: none;
    /* Safari and Chrome */
    -moz-appearance: textfield;
    /* Firefox */
}

/* Style the input field */
.series-find-input::-webkit-inner-spin-button,
.series-find-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.tab-button {
    color: white;
    background-color: rgba(255, 255, 255, 0.137);
    border-radius: 60px;
    margin: 0 5px;
    padding: 12px 20px;
}

.tab-button:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.315);

}

.tab-button.selected {
    color: rgb(29, 29, 29);
    background-color: white;

}

.anime-info-div {
    color: white;
    display: flex;

    justify-content: center;
    /* horizontally center */
    padding: 0px 40px;
    flex-direction: column;
    /* stack items vertically */
}

.profile-roles {
    display: flex;
    align-items: center;
    gap: 7px;
}

.series-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: 20px;
    justify-items: center;
    padding: 20px 8px;
}


.actionButton.playButton {
    margin-bottom: 20px;
    margin-left: 0px;
}

.actionButton.subscribeButton {
    margin-bottom: 20px;
    padding: 15px 20px;
    padding-left: 8px;
    justify-content: center;
    background: #23232360;
    margin-left: 0px;
    width: fit-content;
}

.anime-half-con {
    background: #111111;
    padding: 20px 40px;
    margin: 0px;
    gap: 20px;
    display: flex;
}


.anime-half {
    flex: 2;
}

.anime-half-desc {
    flex: 1;
    padding: 0 10px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Default flex behavior */
}


@media (max-width: 720px) {

    /* Adjust the breakpoint as needed */
    .anime-half-con {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 10px 10px;
    }

    .anime-half-desc {
        flex: 1;
        padding: 0 0px;
    }

    .center-content {
        justify-content: center;
        display: flex
    }

    .pg-anime-text {
        margin-top: 20px;
    }

    .anime-actions-con {
        flex-direction: column-reverse;
        margin: 0px;
    }
}

.anime-info-content {
    position: relative;
    height: 45vh;
    padding: 0 60px;
    padding-top: 80px;
    display: flex;
    align-items: center;
    background-color: #111111;
}

.anime-info-container {
    display: flex;
    justify-content: flex-start;
    z-index: 2;
}

.general-stars-con {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between star and rating */


    h1 {
        margin: 0;
        font-size: 60px;
    }
}

.star-icon {
    width: 80px; /* Adjust size as needed */
    height: 80px;
}

/* Popup Overlay */
.review-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4000;
}

/* Popup Content */
.review-popup-content {
    background: #111111;
    padding: 20px;
    border-radius: 20px;
    max-width: 400px;
    width: auto;
    max-height: 80%;
    text-align: left; /* This should affect inline content */
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Default flex behavior */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    h2 {
        margin: 10px 0;
    }
}

/* Close Button */
.review-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #666;
}

.review-close-button:hover {
    color: #000;
}



@media (max-width: 768px) {

    /* Adjust the breakpoint as needed */
    .anime-info-content {
        height: auto;
        padding: 10px 0px;
        /* Adjust padding as needed */
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .anime-info-container {
        margin-top: 100px;
        flex-direction: column;
        align-items: center;
        text-align: center;
        z-index: 2;
    }

  

    .anime-text-small {


        margin: 5px 0px;
        font-size: 16px;
    }

    .anime-text {

        margin: 10px 0px;
        margin-bottom: 30px;

        font-size: 16px;
    }

    .anime-name {

        margin: 0px 0px;
        margin-top: 10px;
        font-size: 18px;
    }

}