.prem-popup-container {
    z-index: 40000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
 
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
}

.prem-popup-content {
    backdrop-filter: blur(50px);
    border-radius: 20px;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    align-items: center;
    background: #00000095;
    justify-content: center;
    color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    
    input {
       
       
        width: 85%;
       
        margin-top: 50px;
        height: 10px;
    }
}


.prem-popup-email
{
    margin: 20px 0px;
}
.email-error
{
    background-color: #d13751;
    padding: 20px 10px;
    border-radius: 20px;
    font-weight: 700;
}

.prem-cancel-popup-content {
    background: #333;
    border-radius: 20px;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #1d1d1d;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

}

.prem-popup-logo {
    width: 100%;
    margin-bottom: 20px;
}

.prem-popup-feature {
  
    display: flex;
    background: #ffffff23;
    color: white;
    font-weight: 700;
    align-items: center;
    padding: 14px;
    text-align: left;
    border-radius: 15px;
    margin-bottom: 15px;
}

.prem-popup-feature img {
    width: 20px;
    height: 20px;
    fill: #1d1d1d;
    margin-right: 10px;
}

.prem-popup-pricing {
    margin-top: 20px;
}

.cancel-content {
    display: flex;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
}

.cancel-content img {
    margin-left: 25px;
}

.cancel-btn,
.monthly-btn,
.yearly-btn {
    
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 14px;
    border: none;
    border-radius: 15px;
    font-size: 16px;
    cursor: pointer;
    justify-self: center;
    margin: 0px;
    margin-top: 10px;
}

.boosty-btn {
    background: #242424;
    color: white;
    display: block;
    width: 93%;
  
    margin-bottom: 10px;
    padding: 14px;
    border: none;
    border-radius: 15px;
    font-size: 16px;
    cursor: pointer;
    justify-self: center;
    font-weight: 700;
    margin-top: 10px;
    margin: 10px 80px;
    transition: all 0.3s ease-in-out;
}

.boosty-btn:hover{
    scale: 0.96;
    background: #c07300;
}


.monthly-btn {
    background: linear-gradient(90deg, #9c50f3, #6d37d1);
    color: white;
}

.yearly-btn {
    background: #fff;
    color: #333;
}

.cancel-btn {
    background: #1B1B1B;
}

.cancel-btn:hover {
    background: #1d1d1d;
}

.monthly-btn:hover {
    scale: 0.98;
    color: white;
}

.yearly-btn:hover {
    scale: 0.98;
    background: #ffffffc7;
    color: #333;
}

.yearly-btn span:nth-child(2) {
    background: #ffcc00;
    padding: 4px 8px;
    font-size: 14px;
    border-radius: 20px;
    margin-left: 10px;
}

.prem-popup-note {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 20px;
}