.profile-header-photo {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background: #111111;
  background-color: #111111;
  border-radius: 0 0 60px 60px;
  height: 100%;
  width: 100%;

  transform: translateY(-20%);
  object-fit: cover;
  transition: all 1s ease-in-out;
}

.profile-header-photo.sub {
  position: fixed;
  z-index: -1;

  border-radius: 0;
  height: 100vh;
  transition: all 0.3s ease-in-out;
}

.profile-info-content {
  position: relative;
  height: 35vh;
  padding: 0 80px;
  padding-top: 100px;
  display: flex;
  align-items: center;
  background-color: #7575752a;
  transition: opacity 0.3s ease-in-out;
}


.profile-header-photo.visible {
  scale: 1;
  opacity: 0.4;
  transform: translateY(0);
  transition: all 1s ease-in-out;
}

.profile-header-photo.sub.visible {
  scale: 1;
  opacity: 0.3;
  transform: translateY(0);
  transition: all 0.8s ease-in-out;
}

.mod-text {
  margin: 0px;
  padding: 6px 16px;
  font-weight: 600;
  text-align: center;
  background: #7544B2;
  border-radius: 20px;
  font-size: 18px;
}


.editor-text {
  margin: 0px;
  padding: 6px 16px;
  font-weight: 600;
  text-align: center;
  background: #4b44b2;
  border-radius: 20px;
  font-size: 18px;
}

.profile-desc {
  margin: 0px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 22px;
}

.profile-reg-date {
  margin: 0px;
  margin-bottom: 10px;
  opacity: 0.8;
  font-weight: 400;
}

/* Hide the default file input */
input[type="file"] {
  display: none;
}

/* Style the custom upload label */
.upload-label {
  display: inline-block;
  background-color: #222222;
  border: 2px solid #f3f3f325;
  color: #fff;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-label:hover {
  background-color: #7544b244;
  border: 2px solid #7544B2;
  transition: all 0.3s ease;
}

/* Style the file input container */
.upload-section {
  margin: 20px 0;
  width: 80%;
  max-width: 350px;
}

.settings-text {
  margin: 10px 0px;
  padding: 0px;
}

.settings-text-small {
  margin: 10px 0px;
  padding: 0px;
  opacity: 0.5;
  font-size: 14px;
}

.settings-input {
  margin: 0px 0px;
  margin-bottom: 20px;
}

.profile-card {
  background: #1f1f1fc0;
  padding: 10px 22px;
  margin: 10px;
  backdrop-filter: blur(10px);
  border-radius: 24px;

  .button-sec {
    margin: 10px 0px;


    background-color: #ffffff11;
  }

  .button-sec:hover {
    margin: 10px 0px;


    background-color: #ffffff20;
  }
}

.profile-card-icon {
  width: 18px;
  height: 18px;
}

.profile-card-title {
  margin: 0px;
  padding: 0px;
  margin-left: 4px;
  text-align: left;
}

.profile-card-time-big {
  margin: 0px;
  margin-top: 20px;
  padding: 0px;
  text-align: left;
}

.profile-card-time {
  margin: 0px;
  padding: 0px;
  opacity: 0.5;
  margin-bottom: 40px;
  text-align: left;
}

.profile-half-con {
  background: #7575752a;
  padding: 20px 40px;
  margin: 0px;
  display: flex;
}


.comments-profile {
  background: #181818;
  padding: 10px 60px;
  margin: 0px;
  display: flex;
  flex-direction: column;
}

.watch-his-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 20px;
  justify-items: center;
  padding: 20px 8px;
}

.pie-chart-container {
  height: 200px;
  padding: 0px;
  margin: 0px;
}

@media (max-width: 720px) {
  .profile-half-con {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px 10px;
  }

  .profile-info-content {
    height: auto;
    padding: 20px 0px;
    /* Adjust padding as needed */
    flex-direction: column;
    align-items: center;
    text-align: center;
}


  .pie-chart-container {
    height: 140px;
    padding: 0px;
    margin: 0px;
  }

  .comments-profile {
    background: #111111;
    padding: 10px 20px;
    margin: 0px;
    display: flex;
  }

  .profile-card {
    background: #ffffff1f;
    padding: 10px 22px;
    margin: 8px 0px;
    border-radius: 24px;
  }
}

.profile-friends-con {
  display: inline;
  flex-direction: row;

  overflow-x: scroll;
  /* enables horizontal scrolling */
  white-space: wrap;

  /* prevents line breaks between items */
  div {
    margin: 4px;
  }
}

.comment-section {
  margin-top: 20px;
}

.comment {
  padding: 5px 24px;
  background-color: #303030;
  border-radius: 20px;
  margin-bottom: 10px;

}

.comment-date {
  font-size: 0.9em;
  font-weight: 800;
  color: #9e9e9e;
}

.comment-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.comment-form textarea {
  width: 80%;
  height: 100px;
  max-width: 500px;

  margin-top: 0px;
  margin-bottom: 10px;


  resize: none;
}

.comment-form textarea:focus {
  outline: none;

}

.comment-form button {
  align-self: flex-start;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 4000;
}

.popup {
  background: #111111;
  padding: 20px;
  border-radius: 20px;
  max-width: 400px;
  width: auto;
  max-height: 80%;
  text-align: left; /* This should affect inline content */
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Default flex behavior */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.popup .content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: calc(100vh - 160px);
  overflow-y: auto;


  gap: 20px;
}

.popup h2 {
  margin-top: 0; /* Ensure no unintended margin */
  text-align: left; /* Left-align text explicitly */
  display: block; /* Flex might not be necessary */
}

.popup-title {
  margin-top: 10px; /* Add units for margin */
  text-align: left; /* Ensure text alignment */
  display: block; /* Flex might not be necessary */
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

}

.gift-profile-button {
  margin: 0px;
  padding: 0px 10px;
  width: auto;
  height: 50px;
  border-radius: 100px;
  display: flex;
  background: #7544B2;
  transition: all 200ms ease-in-out;


  p {
    padding: 0px 10px;
  }

  img {
    margin: 0px;
    width: 50px;
    height: 50px;

    padding: 0px;
    transition: all 200ms ease-in-out;
  }

  img:hover {
    margin: 0px;
    width: 50px;
    height: 50px;


    padding: 0px;
  }
}

.profile-button {
  margin: 0px;
  padding: 0px 10px;
  width: auto;
  height: 50px;
  border-radius: 20px;
  display: flex;

  transition: all 200ms ease-in-out;
  background: #ffffff25;

  p {
    padding: 0px 10px;
  }

  img {
    margin: 0px;
    width: 50px;
    height: 50px;

    padding: 0px;
  }
}

.profile-button:hover {
  background: #ffffff15;

}

@media (max-width: 600px) {
  .popup {
    max-width: 100%;
    width: 100%;
    max-height: 70%;
    height: 100%;
    border-radius: 0;
  }

  .popup .content {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    max-height: calc(100vh - 120px);
  }

  .buttons {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    margin-top: 10px;
  }
}

@media (max-height: 500px) {
  .popup {
    max-height: 80%;
  }

  .popup .content {
    max-height: calc(80vh - 160px);
  }
}


.fr-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.fr-popup {
  background-color: #111111;
  padding: 10px 30px;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

.fr-close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.fr-friends-list {
  max-height: 300px;
  overflow-y: auto;
}

.fr-friend-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.fr-friend-item p {
  margin-left: 10px;
}