.headerMobile {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
    backdrop-filter: blur(0px); 
    left: 0;
    width: 100%;
    z-index: 1000;
    /* Ensure header is on top of all content */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent);
    transition: all 200ms linear;
  }
  
  .headerScrolled {
    /* Additional styles for scrolled header */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), transparent);
    backdrop-filter: blur(10px); 
    transition: all 200ms linear;
  }
  
  .menuIcon {
    margin-right: 20px;
    cursor: pointer;
  }
  
  .menuLine {
    width: 25px;
    height: 3px;
    background-color: white;
    border-radius: 20px;
    margin: 3px 0;
  }

  .header-menu-bar {
    padding-top: 100px;

    position: fixed;
    top: 0;
    text-align: center;
    gap: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background: #000000a9;
    transition: all 200ms ease-in-out;
  }

  .header-menu-bar.hidden {
    opacity: 0;
    padding-top: 100px;
    position: absolute;
    z-index: -100;
    width: 100%;
    height: 0px;
   
    background: #000000a9;
    transition: all 200ms ease-in-out;
  }
  
  
  .logo {
    margin-left: 20px;
  }
  
  .rightContainer {
    margin-left: auto;
    display: flex;
    gap: 30px;
    align-items: center;
  }
  
  .accountImage {
    width: 50px;
    height: 50px; 
   
  }  