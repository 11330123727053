.settings-menu {
    background-color: #1B1B1B;
    color: white;
    border-radius: 16px;
    overflow: hidden;
    position: absolute;
    bottom: 50px;
    right: 3%;
    z-index: 10000;
    width: 250px;
    height: auto;
    scale: 1;
    opacity: 1;
    transition: all 150ms ease-in-out;
}

.settings-menu.hidden {
    scale: 0.8;
    transform: transformX(10%);
    opacity: 0;
    visibility: hidden;
 
    transition: all 150ms ease-in-out;
}

.settings-text {
    font-size: 14px;
    display: flex;
    font-weight: 400;
   
    opacity: 0.5;
}

.debug-text {
    font-size: 14px;
    display: flex;
    font-weight: 400;
    word-break: break-all;
    opacity: 0.5;
}


.settings-option {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    padding: 10px 20px;

    align-items: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 1em;
    font-family: 'Montserrat', sans-serif;
}

.settings-option:hover {
    background-color: #353535;
}

.settings-option span {
    font-size: 14px;
}

.option-value {
    background-color: #5a5a5a;
    padding: 4px 12px;
    border-radius: 20px;
}
.option-value.high {
    background-color: #691fbe;
    padding: 4px 12px;
    border-radius: 20px;
}

.settings-option.debug {
    color: #555;
    cursor: default;
}

.settings-option.debug:hover {
    color: #cacaca;
    cursor: default;
}


.settings-page {
    animation: slideIn 0.2s ease-in-out;

   
}


@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.debug-info-container {
    max-height: 40vh;
    overflow-y: auto;
    padding: 10px;
    font-size: 0.7rem;
}

.debug-section {
    margin-bottom: 5px;
    padding: 5px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.debug-section h3 {
    margin: 0 0 4px 0;
    color: #750099;
    font-size: 0.9rem;
}

.debug-section pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 0;
    font-family: monospace;
}

.copy-debug-btn {
    position: sticky;
    top: 0;
    background: #2a2a2a;
    color: white;
    border: none;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
    z-index: 1;
}

.copy-debug-btn:hover {
    background: #3a3a3a;
}